import { 
    defineComponent,
    ref, 
    reactive, 
    onMounted, 
    onUnmounted,
    getCurrentInstance,
    ComponentInternalInstance 
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { SET_HIGHLIT, SET_PUBLISH1, SET_PUBLISH2 } from '@/utils/constant'
import { createJob, jobUpdate } from '@/api/index'

import './index.less'

export default defineComponent({
    name: 'TopHeader',
    setup () {
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const route = useRoute()
        const role1 = ref(null)
        const role2 = ref(null)
        const role3 = ref(null)
        const state: any = reactive({
            hightlight: [],
            description: '', //职位描述
            recommendReward: true, //有内推奖金
            recommendRewardRole: ""
        })

        onMounted(() => {
            _initHightlight()
            _init()
        })

        onUnmounted(() => {
            console.log('页面销毁。')
        })

        const _clear = () => {
            localStorage.removeItem(SET_HIGHLIT)
            localStorage.removeItem(SET_PUBLISH1)
            localStorage.removeItem(SET_PUBLISH2)
        }

        const _publish2 = () => {
            let res: any = localStorage.getItem(SET_PUBLISH2)
            if (res) {
                res = JSON.parse(res)
                console.log(res.description,'什么。。。')
                state.description = res.description
                state.recommendReward = res.recommendReward
                state.recommendRewardRole = res.recommendRewardRole
            }
            if (state.recommendRewardRole){
                const role = JSON.parse(state.recommendRewardRole)
                role1.value = role[0]
                role2.value = role[200]
                role3.value = role[400]
            }
        }

        const _init = () => {
            _publish2()
            const { id, isCopy } = route.query
            if (id || isCopy) {
                console.log('jinlai....')
                _initEditeJobInfo()
            }
        }

        const _initEditeJobInfo = () => {
            let job: any = localStorage.getItem(SET_PUBLISH1)
            let highlit = localStorage.getItem(SET_HIGHLIT)
            const res: any = localStorage.getItem(SET_PUBLISH2)

            if (job && !res) {
                job = JSON.parse(job)
                state.description = job.description
                state.recommendReward = job.recommendReward
                state.recommendRewardRole = job.recommendRewardRole

                if (state.recommendRewardRole){
                    const role = JSON.parse(job.recommendRewardRole)
                    role1.value = role[0]
                    role2.value = role[200]
                    role3.value = role[400]
                }
                let highlights = job.highlights
                let highlightsId = job.highlightsId
                if (highlights) {
                    highlightsId = JSON.parse(highlightsId)
                    highlights = JSON.parse(highlights)
                    const array: any = []
                    highlights.forEach((item: any, index: number) => {
                        const obj = { id: highlightsId[index], name: item, checked: true }
                        array.push(obj)
                    })
                    state.hightlight = array
                    if (!highlit) {
                        localStorage.setItem(SET_HIGHLIT, JSON.stringify(state.hightlight))
                    } else {
                        highlit = JSON.parse(highlit)
                        state.hightlight = highlit
                    }
                    
                } else {
                    _publish2()
                }
            }
        }

        /**
         * 删除职位亮点
         * @param item 
         */
        const onDeleteHightlight = (item: any) => {
            state.hightlight.forEach((ite: any, index: number) => {
                if (item.id === ite.id) {
                    state.hightlight.splice(index, 1)
                }
            })
            if (state.hightlight.length === 0) {
                localStorage.removeItem(SET_HIGHLIT)
            } else {
                localStorage.setItem(SET_HIGHLIT, JSON.stringify(state.hightlight))
            }
           // console.log(state.hightlight)
        }

        /**
         * 初始化职位亮点
         */
        const _initHightlight = () => {
            let res = localStorage.getItem(SET_HIGHLIT)
            if (res) {
                res = JSON.parse(res)
                state.hightlight = res;
            }
        }

        //上一步
        const goBack = () => {
            onReward()
            localStorage.setItem(SET_PUBLISH2, JSON.stringify(state))
            //router.push({path: '/publish'})
            router.back()
        }

        /**
         * 取消-跳转回职位列表
         */
        const onCancel = () => {
            localStorage.removeItem(SET_HIGHLIT)
            localStorage.removeItem(SET_PUBLISH1)
            localStorage.removeItem(SET_PUBLISH2)
            router.push({path: '/job'})
        }

        //职位亮点
        const onHighlitsPage = () => {
            onReward()
            localStorage.setItem(SET_PUBLISH2, JSON.stringify(state))
            router.push({path: '/highlights'})
        }

        /**
         * 处理推荐奖励
         */
        const onReward = () => {
            if (state.recommendReward) {
                const obj:any = {}//{0: role1.value, 200: role2.value, 400: role3.value }
               
                if (role1.value) {
                    obj['0'] = role1.value
                }
                if (role2.value) {
                    obj['200'] = role2.value
                }
                if (role3.value) {
                    obj['400'] = role3.value
                }

                if (!role1.value && !role2.value && !role3.value) {
                    state.recommendRewardRole = ""
                } else {
                    state.recommendRewardRole = JSON.stringify(obj)
                }
            } else {
                state.recommendRewardRole = ""
            }
        }

        const onSubmit = async () => {
            if (!state.description) {
                return proxy.$toast('请输入职位介绍!'); 
            }
            onReward()
            let res: any = localStorage.getItem(SET_PUBLISH1)
            if (!res)return 
            res = JSON.parse(res)
            const highlights = state.hightlight.map((item: any) => item.name)
            const highlightsId = state.hightlight.map((item: any) => item.id)
            const obj = { ...res, ...state, status: 1 }
            obj.highlights = JSON.stringify(highlights)
            obj.highlightsId = JSON.stringify(highlightsId)
            delete obj.hightlight
            console.log(obj,'数据。。。。')

            //修改
            if (obj.id) {

                let bool = false 
                if (obj.negotiable.length > 1) {
                    bool = true
                } else {
                    bool = obj.negotiable[0]
                } 

                obj.negotiable = bool

                const result: any = await jobUpdate(obj)
                if (result.code === 0) {
                    proxy.$toast('职位更新成功!'); 
                    let timeout: any = setTimeout(() => {
                        clearTimeout(timeout)
                        timeout = null 
                        router.push({path: '/job'})
                    }, 1000)
                } else {
                    proxy.$toast('职位更新失败!'); 
                }
                _clear()
                return 
            }

            if(!obj.negotiable || obj.negotiable.length === 0) {
                obj.negotiable = false
            } else if (obj.negotiable.length >= 1) {
                obj.negotiable = obj.negotiable[0]
            }
            console.log(obj, 'sha a ....')
            const result: any = await createJob(obj)
            
            if (result.code === 0) {
                _clear()
                proxy.$toast('职位发布成功!'); 
                let timeout: any = setTimeout(() => {
                    clearTimeout(timeout)
                    timeout = null 
                    router.push({path: '/job'})
                }, 1000)
            } else {
                proxy.$toast('职位发布失败!'); 
            }
        }

        return () => (
            <div class="publis-2-page">
                <div class="header-box flex-box justify-content-between">
                    <div>发布职位（2/2）</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <ul class="list-item list-item2">
                    <li class="item">
                        <div class="header-item">职位描述</div>
                        <div class="hightlit-title">职位亮点（最多五个）</div>
                        <div class="flex-box highlight-container">
                            {
                                state.hightlight.map((item: any, index: number) => {
                                    return (
                                        <div class="highlight-box" key={ index }>
                                            { item.name }
                                            <van-icon onClick={ ()=> onDeleteHightlight(item) } class="close-item" name="close" />
                                        </div>
                                    )
                                })
                            }
                            <span onClick={onHighlitsPage}>+选择</span>
                        </div>
                        <div>
                            <div class="title-item"><label class="red">*</label>职位介绍</div>
                            <div class="textarea-box">
                                <van-field
                                    v-model={state.description}
                                    rows="3"
                                    autosize
                                    type="textarea"
                                    maxlength="5000"
                                    placeholder="请输入"
                                    show-word-limit={false}
                                    class="panl"
                                    />
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="header-item">职位描述<span class="employee-title">（邀请员工内推，快速找到靠谱人才）</span></div>
                        <div class="money-box">
                            <div class="flex-box justify-content-between align-items-center money-header">
                                <div>员工推荐的求职者:</div>
                                <div class="flex-box align-items-center">
                                    <span class="mr20">开启内推</span>
                                    <van-switch v-model={state.recommendReward} size="12px" active-color="#ee0a24" inactive-color="#dcdee0" />
                                </div>
                            </div>
                            <ul class="line-box">
                                <li>
                                    <span class="sqaure-box"><van-icon name="success" /></span>
                                    <div class="first-block">
                                        推荐成功，奖励:
                                       <van-field v-model={role1.value} type="digit" class="money-input" />
                                        元
                                    </div>
                                    <div class='yaoyue-box'>(邀约面试)</div>
                                </li>
                                <li>
                                    <span class="middel-sqaure"><van-icon name="success" /></span>
                                    面试通过，奖励:
                                    <van-field v-model={role2.value} type="digit" class="money-input" />
                                    元
                                </li>
                                <li>
                                    <span class="last-sqaure"><van-icon name="success" /></span>
                                    <div class="last-block">
                                        实际到岗，奖励:
                                        <van-field v-model={role3.value} type="digit" class="money-input" />
                                        元
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="item">
                        <div class="koukuan-info-box">
                            <div class='header'>
                                <img src='https://img.fanwoon.com/redtishi%402x.png' alt='' />
                                <span>推荐奖金说明：</span>
                            </div>
                            <div class='intro'>
                            <div>1.当您邀约面试时，会预扣推荐奖金至平台；</div>
                                    <div>2.邀约面试起21天内</div>
                                    <div>
                                        <div>(1)对求职者的“通过”操作，会结算相应环节的预扣奖金，发放给推荐者；</div>
                                        <div>(2)对求职者的“淘汰”操作，会返还相应环节预扣的奖金至您的账户；</div>
                                        <div>(3)若21天后，未登录平台进行任何操作，会将预扣的奖金全部自动发放给推荐者；</div>
                                    </div>
                                    <div>3.在21天到期前，会有短信提醒，您可申请延长一次7天的自动结算周期</div>
                            </div>
                        </div>
                    </li>

                </ul>
                <div class="footer-bars">
                    <button class="canel-btn" onClick={onCancel}>取消</button>
                    <button class="pre-btn" onClick={goBack}>上一步</button>
                    <button class="sub-btn" onClick={ onSubmit }>提交发布</button>
                </div>
            </div>
        )
    }
})